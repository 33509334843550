import React, { FC, useMemo } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { DialogPreview, DialogStateShape, dialogModel } from '@cp/entities/dialog';
import { useSelector } from 'react-redux';
import { User } from '@cp/entities/user';

export interface DialogListItemProps {
  currentUser: User;
  dialogId: EntityId;
  onSelect: (dialogId: number) => void;
}

export const DialogListItem: FC<DialogListItemProps> = ({ dialogId, onSelect, currentUser }) => {
  const currentMemberSelectorParams = useMemo(
    () => ({
      user: currentUser,
      dialogId,
    }),
    [dialogId, currentUser],
  );
  const participant = useSelector<DialogStateShape, ReturnType<typeof dialogModel.selectors.initialParticipantMemberSelector>>((state) =>
    dialogModel.selectors.initialParticipantMemberSelector(state, currentMemberSelectorParams),
  );
  const unreadMessagesCount = useSelector<DialogStateShape, ReturnType<typeof dialogModel.selectors.unreadMessagesCountInDialogSelector>>(
    (state) => dialogModel.selectors.unreadMessagesCountInDialogSelector(state, currentMemberSelectorParams),
  );
  const lastMessage = useSelector<DialogStateShape, ReturnType<typeof dialogModel.selectors.dialogLastMessageSelector>>((state) =>
    dialogModel.selectors.dialogLastMessageSelector(state, dialogId),
  );

  return participant ? (
    <DialogPreview
      component="li"
      dialogId={dialogId as number}
      key={dialogId}
      message={lastMessage}
      onSelect={onSelect}
      participant={participant}
      unreadMessagesCount={unreadMessagesCount}
    />
  ) : null;
};
