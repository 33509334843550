import React, { ReactNode, useRef } from 'react';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { InfoSolidIcon } from '@cp/ds/src/icons/infoSolid';
import { useReadNotification } from '../../model/useReadNotification';
import { MessageComponentProps } from '../context';

export interface DialogMessageNotificationProps extends Omit<MessageComponentProps, 'message'> {
  message?: DialogMessageViewModel;
  text?: ReactNode;
}

const ReadNotificationEffect: React.FC<{ containerRef: React.RefObject<HTMLDivElement>; message: DialogMessageViewModel }> = ({
  containerRef,
  message,
}) => {
  useReadNotification(containerRef, message);
  return null;
};

export const DialogMessageNotification: React.FC<DialogMessageNotificationProps> = ({ message, text, isPreview }) => {
  const ref = useRef<HTMLDivElement>(null);

  if (isPreview) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{text}</>;
  }

  return (
    <Box ref={ref} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {message && <ReadNotificationEffect containerRef={ref} message={message} />}
      <InfoSolidIcon fontSize="1.125rem" sx={{ mr: 1 }} />
      <Typography variant="caption bold">{text}</Typography>
    </Box>
  );
};
