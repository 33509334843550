import React from 'react';
import { DialogMessageRenderer, MessageComponentProps } from '@cp/entities/dialog';
import { FallbackMessage } from './fallback';
import { DialogMessageWithErrorBoundary } from './errorBoundary';
import { messageComponentMap } from './componentMap';

export const MessageRenderer: React.FC<MessageComponentProps> = (props) => {
  const MessageComponent = (messageComponentMap[props.message.type] as DialogMessageRenderer) || FallbackMessage;

  return (
    <DialogMessageWithErrorBoundary dialogId={props.dialogId} message={props.message}>
      <MessageComponent {...props} />
    </DialogMessageWithErrorBoundary>
  );
};
