// eslint-disable-next-line import/no-duplicates
import format from 'date-fns/format';
// eslint-disable-next-line import/no-duplicates
import ru from 'date-fns/locale/ru';
import { DateFormat } from '@common/const/date';

export const getCatalogItemDateFormat = (date: Date): DateFormat => {
  if (date.getFullYear() === new Date().getFullYear()) {
    return DateFormat.CatalogItemDateThisYear;
  }

  return DateFormat.CatalogItemDateAnotherYear;
};

export const formatCatalogItemDate = (date: Date): string => {
  return format(date, getCatalogItemDateFormat(date), { locale: ru });
};
