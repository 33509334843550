import React from 'react';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { EntityId } from '@reduxjs/toolkit';
import { FallbackMessage } from './fallback';

export interface MessageErrorBoundaryProps extends React.PropsWithChildren {
  message: DialogMessageViewModel;
  dialogId: EntityId;
}

export class DialogMessageWithErrorBoundary extends React.Component<MessageErrorBoundaryProps, { hasError: boolean; error?: Error }> {
  constructor(props: MessageErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch() {
    //
  }

  render() {
    if (this.state.hasError) {
      return <FallbackMessage dialogId={this.props.dialogId} isPreview={false} message={this.props.message} />;
    }
    return this.props.children;
  }
}
