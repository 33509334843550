import { MessageType } from '@common/model/dialog/message/messageType';
import { DialogMessageRenderer, DialogMessageText } from '@cp/entities/dialog';
import { FavoriteNotificationRenderer } from './favorite';
import { VacancyResponseRenderer } from './vacancyResponse';
import { ClientContactsRenderer } from './clientContacts';
import { FavoriteNotificationResponseRenderer } from './favoriteResponse';
import { UnFavoriteNotificationRenderer } from './unfavorite';

export type MessageTypeToComponentMap = {
  [T in MessageType]: DialogMessageRenderer<T>;
};

export const messageComponentMap: Partial<MessageTypeToComponentMap> = {
  [MessageType.Text]: DialogMessageText,
  [MessageType.NotificationFavorite]: FavoriteNotificationRenderer,
  [MessageType.NotificationUnFavorite]: UnFavoriteNotificationRenderer,
  [MessageType.NotificationFavoriteResponse]: FavoriteNotificationResponseRenderer,
  [MessageType.VacancyResponse]: VacancyResponseRenderer,
  [MessageType.ClientContacts]: ClientContactsRenderer,
} as const;
