import { ICurrentUserProvider } from '@cp/entities/dialog';
import { User, UserType } from '@cp/entities/user';

export interface DialogUserStorage {
  set(u: User): void;
  get(): User;
  reset(): void;
}

export class DialogUserService implements ICurrentUserProvider {
  #storage: DialogUserStorage;
  #loggedInUserProvider: () => User;

  constructor(storage: DialogUserStorage, loggedInUserProvider: () => User) {
    this.#loggedInUserProvider = loggedInUserProvider;
    this.#storage = storage;
    const u = storage.get();
    if (!u || u.type === UserType.Guest) {
      this.#storage.set(this.#loggedInUserProvider());
    }
  }

  getUser(): User {
    return this.#storage.get() as User;
  }

  setUser(user: User) {
    this.#storage.set(user);
  }

  reset() {
    this.#storage.reset();
  }
}
