import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { currentDialogIdSelector } from './store/currentDialogSelectors';
import { currentDialogMemberSelector } from './store/selectors';
import { DialogReducerStateShape } from './store/dialog';
import { useCurrentUserContext } from './user.ctx';

export const useCurrentDialogMember = (props: { dialogId?: EntityId } = {}): DialogMemberViewModel | undefined => {
  const provider = useCurrentUserContext();
  const currentDialogId = useSelector(currentDialogIdSelector);
  const dialogId = props.dialogId ?? currentDialogId;

  return useSelector<DialogReducerStateShape, ReturnType<typeof currentDialogMemberSelector>>((state) =>
    currentDialogMemberSelector(state, { dialogId: dialogId as EntityId, user: provider.getUser() }),
  );
};
