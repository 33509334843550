import React from 'react';
import { Button } from '@cp/ds/src/components/button';
import { DeleteIcon } from '@cp/ds/src/icons';
import { adminCatalogFeedbackApi } from 'src/entities/catalog/feedback';
import { DeleteFeedbackButtonProps } from './types';

export const DeleteFeedbackButton: React.FC<DeleteFeedbackButtonProps> = ({ id }) => {
  const [deleteFeedback, { isLoading }] = adminCatalogFeedbackApi.endpoints.deleteFeedback.useMutation();

  const handleDelete = () => {
    deleteFeedback({ pathParams: { id } });
  };

  return (
    <Button disabled={isLoading} onClick={handleDelete} sx={{ ml: 'auto', mr: 0 }} variant="text">
      <DeleteIcon fontSize="1.5rem" />
    </Button>
  );
};
