import { useSelector } from 'react-redux';
import { User } from '../../user';
import { currentUserSelector } from './index';

/**
 * Return object presenting user viewing page
 */
export const useCurrentUser = (): User => {
  return useSelector(currentUserSelector);
};
