import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Box } from '../box';
import { IconButton } from '../iconButton';
import { StyledListItem } from '../styled/styledListItem';
import { StyledUnorderedList } from '../styled/styledUnorderedList';
import { Typography } from '../typography';
import { pageTypeToIcon } from './const';
import { PaginationItem, PaginationProps, paginationItemsSeqMaxLengthDict } from './model';
export type { PaginationProps } from './model';

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  lastPage,
  itemsSequenceMaxLength,
  pageLinkGenerator,
  onChange,
  sx,
}) => {
  const isMobile = useIsMobile();
  const seqMaxLength = { ...paginationItemsSeqMaxLengthDict, ...itemsSequenceMaxLength }[isMobile ? 'mobile' : 'desktop'] as number;

  const pagination = React.useMemo(() => {
    const result: PaginationItem[] = [];
    const siblings = Math.round((seqMaxLength - 1) / 2); // eslint-disable-line @typescript-eslint/no-magic-numbers

    if (currentPage > 1) {
      result.push({ type: 'previous', page: currentPage - 1 });
      result.push({ type: 'page', page: 1 });
    }

    const maxLeftSiblings = Math.max(currentPage - 2, 0); // eslint-disable-line @typescript-eslint/no-magic-numbers
    const leftSiblings = Math.min(siblings, maxLeftSiblings);

    if (maxLeftSiblings > leftSiblings) {
      result.push({ type: 'ellipsis', page: 0 });
    }

    for (let siblingPage = currentPage - leftSiblings; siblingPage < currentPage; siblingPage++) {
      result.push({ type: 'page', page: siblingPage });
    }

    result.push({ type: 'current', page: currentPage });

    const maxRightSiblings = Math.max(lastPage - currentPage - 1, 0);
    const rightSiblings = Math.min(siblings, maxRightSiblings);
    const lastRightSibling = currentPage + rightSiblings;

    for (let siblingPage = currentPage + 1; siblingPage <= lastRightSibling; siblingPage++) {
      result.push({ type: 'page', page: siblingPage });
    }

    if (maxRightSiblings > rightSiblings) {
      result.push({ type: 'ellipsis', page: Number.MAX_VALUE });
    }

    if (lastPage > currentPage) {
      result.push({ type: 'page', page: lastPage });
      result.push({ type: 'next', page: currentPage + 1 });
    }

    return result.map((v) => {
      if (v.type !== 'ellipsis' && pageLinkGenerator) {
        v.href = pageLinkGenerator(v.page);
      }
      return v;
    });
  }, [lastPage, currentPage, pageLinkGenerator, seqMaxLength]);

  return (
    <Box
      component={StyledUnorderedList}
      itemScope
      itemType="http://schema.org/SiteNavigationElement/Pagination"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
        ...(pagination.length > 1 ? { '& > *:not(:last-child)': { mr: 2 } } : undefined),
        ...(pagination.length > seqMaxLength ? { '& > *:first-of-type': { mr: 5 }, '& > *:last-child': { ml: 3 } } : undefined),
        ...sx,
      }}
    >
      {pagination.map((item) => {
        const { type, page, href } = item;
        let content: React.ReactElement;

        if (type === 'ellipsis') {
          content = <Typography variant="button secondary">...</Typography>;
        } else {
          const Icon = pageTypeToIcon[type];
          content = (
            <IconButton
              aria-label={`Перейти на страницу ${page}`}
              color={page === currentPage ? 'gray dark' : 'white'}
              component={href ? 'a' : 'button'}
              href={href}
              onClick={(event) => onChange(event, page, item)}
              sx={{ minWidth: '2.5rem', minHeight: '2.5rem' }}
            >
              {type === 'page' || type === 'current' ? <Typography variant="button secondary">{page}</Typography> : Icon && <Icon />}
            </IconButton>
          );
        }

        return <StyledListItem key={`${type}-${page}`}>{content}</StyledListItem>;
      })}
    </Box>
  );
};
