import { createContext, useContext } from 'react';
import { DependencyToken, IoContainer } from './types';

const ctx = createContext<IoContainer>({
  get: () => {
    throw new Error('IoC Container context not initialized');
  },
});
ctx.displayName = 'IoC';
export const IocContext = ctx.Provider;
const useIoContainer = (): IoContainer => useContext(ctx);

export const useInjection = <T>(token: DependencyToken<T>) => {
  const container = useIoContainer();
  return container.get(token);
};
