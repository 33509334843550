import React from 'react';
import { Pagination } from '@cp/ds/src/components/pagination';
import {
  DialogContextProvider,
  DialogContextType,
  DialogList as DialogListEntity,
  DialogUserContextProvider,
  ICurrentUserProvider,
  coreDialogApi,
  dialogModel,
  useMessageResolver,
} from '@cp/entities/dialog';
import { useCurrentUser } from '@cp/entities/currentUser';
import { useDispatch, useSelector } from 'react-redux';
import { DialogListItem } from './dialogListItem';
import { MessageRenderer } from './messageRenderer';

export interface DialogListProps {
  onSelectDialog: (dialogId: number) => void;
  onChangePage: (nextPage: number) => void;
  page?: number;
}

export const DialogList: React.FC<DialogListProps> = ({ onSelectDialog, page = 1, onChangePage }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const totalPages = useSelector(dialogModel.selectors.dialogListTotalPagesSelector);
  const dialogIds = useSelector(dialogModel.selectors.dialogsIdsSelector);
  const pagination = useSelector(dialogModel.selectors.dialogListPaginationSelector);
  const { data: dialogsList, isLoading } = coreDialogApi.useGetDialogsQuery({ page, pagination });
  const currentUserProvider: ICurrentUserProvider = React.useMemo(() => ({ getUser: () => currentUser }), [currentUser]);

  React.useEffect(() => {
    if (!dialogsList) {
      return;
    }
    dispatch(dialogModel.actions.dialogsLoaded({ ...dialogsList, page }));
  }, [page, dialogsList, dispatch]);

  const contextValue: DialogContextType = React.useMemo(
    () => ({
      useMessageResolver,
      MessageComponent: MessageRenderer,
    }),
    [],
  );

  return (
    <DialogUserContextProvider value={currentUserProvider}>
      <DialogContextProvider value={contextValue}>
        <DialogListEntity
          isLoading={isLoading}
          pagination={
            totalPages > 1 ? (
              <Pagination
                currentPage={page}
                lastPage={totalPages}
                onChange={(_, nextPage) => onChangePage(nextPage)}
                sx={{ my: 3, justifyContent: 'center' }}
              />
            ) : null
          }
        >
          {dialogIds.map((dialogId) => (
            <DialogListItem currentUser={currentUser} dialogId={dialogId} key={dialogId} onSelect={onSelectDialog} />
          ))}
        </DialogListEntity>
      </DialogContextProvider>
    </DialogUserContextProvider>
  );
};
