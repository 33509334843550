import 'reflect-metadata';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'cookies-js';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { REACT_APP_CONTAINER_ID } from '@common/const/reactApp';
import { IocContext } from '@cp/shared/di';
import { DiContainer } from 'src/app/ioc';
import { CONFIG_MANAGER } from 'src/shared/config';
import { createConfig } from 'src/app/config';
import { ADMIN_ROUTE_RESOLVER, AdminRouteResolver } from 'src/entities/adminRoute';
import { App } from './app';
import { configureAppStore, createReduxPersistConfig } from './redux';
import { createEmotionCache } from './createEmotionCache';
import { AppSuggestionsProvider } from './app/providers/suggestions';
import { AppDeclinationProvider } from './app/providers/declination';

const diContainer = new DiContainer();
diContainer.bind(CONFIG_MANAGER, createConfig());
diContainer.bind(ADMIN_ROUTE_RESOLVER, new AdminRouteResolver());
const store = configureAppStore({
  container: diContainer,
  persistConfig: createReduxPersistConfig({ cookiesAdapter: Cookies }),
});
const container = document.getElementById(REACT_APP_CONTAINER_ID) as Element;
const root = createRoot(container);
const cache = createEmotionCache();

persistStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <IocContext value={diContainer}>
        <AppDeclinationProvider>
          <AppSuggestionsProvider>
            <CacheProvider value={cache}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CacheProvider>
          </AppSuggestionsProvider>
        </AppDeclinationProvider>
      </IocContext>
    </Provider>
  </React.StrictMode>,
);

if (module.hot) {
  module.hot.accept();
}
