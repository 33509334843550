import { Drawer, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import { TRANSITION_DURATION, Z_INDEX } from './const';
import { ModalViewBaseProps } from './model';

export const DrawerBottomView: React.FC<ModalViewBaseProps> = ({ children, onClose, isOpen, swipeable = true, modalProps }) => {
  const theme = useTheme();
  const ModalComponent = swipeable ? SwipeableDrawer : Drawer;

  return (
    <ModalComponent
      {...modalProps}
      PaperProps={{
        sx: {
          borderTopLeftRadius: `${theme.borderRadius.large}px`,
          borderTopRightRadius: `${theme.borderRadius.large}px`,
          backgroundColor: theme.palette.background.paper,
          maxHeight: 0.87,
        },
      }}
      anchor="bottom"
      onClose={onClose}
      onOpen={() => undefined}
      open={isOpen}
      sx={{ zIndex: Z_INDEX }}
      transitionDuration={TRANSITION_DURATION}
      variant="temporary"
    >
      {children}
    </ModalComponent>
  );
};

export default DrawerBottomView;
