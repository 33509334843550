/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { FormEventHandler, KeyboardEventHandler, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@cp/ds/src/components/box';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { AttachButton, MessageInput, SendButton, SendForm, coreDialogApi, useDialog } from '@cp/entities/dialog';
import { SxProps } from '@cp/ds';
import { handleApiServerError } from '@cp/utils/apiServer/errorHandler';
import { AnnounceHintWrapper } from '@cp/ds/src/components/announceHintWrapper';
import { FormField } from '@cp/ds/src/components/form';
import { Button, ButtonProps } from '@cp/ds/src/components/button';
import { UserType } from '@cp/entities/user';
import { useDialogUserService } from 'src/features/dialog/view';
import { MessageFormValues, generateCreateMessageRequest } from '../../model';

export interface SendMessageFormProps {
  sx?: SxProps;
  onSended?: () => void;
  SendButton?: (SendButtonProps: ButtonProps) => JSX.Element;
  tools?: React.ReactNode | React.ReactNode[];
}

export const SendMessageForm = React.forwardRef<HTMLFormElement, SendMessageFormProps>(({ onSended, tools }, ref) => {
  const isMobile = useIsMobile();
  const dialog = useDialog();
  const dialogUserService = useDialogUserService();
  const form = useForm<MessageFormValues>({
    defaultValues: {
      dialogId: dialog?.id,
      message: '',
    },
  });
  const message = form.watch('message');
  const [sendMessage, { isLoading: isSendingMessage }] = coreDialogApi.useSendMessageMutation();
  const handleSendMessage = useCallback(
    async (formValues: MessageFormValues) => {
      const user = dialogUserService.getUser();
      const dto = generateCreateMessageRequest({
        ...formValues,
        dialogId: dialog?.id as number,
        asClient: user.type === UserType.Client ? user.id : undefined,
      });
      const response = await sendMessage(dto);
      if ('error' in response) {
        handleApiServerError(response.error, { form, formFieldNameForCommonErrors: 'message' });
      } else {
        form.reset({ message: '' });
        onSended?.();
      }
    },
    [form, sendMessage, onSended, dialog, dialogUserService],
  );
  useEffect(() => {
    if (dialog?.id) {
      form.setValue('dialogId', dialog.id);
    }
  }, [form, dialog?.id]);

  const attachment =
    !isMobile || !message.length ? (
      <AnnounceHintWrapper>
        {({ setAnnounceMessageVisible }) => <AttachButton onAttach={() => setAnnounceMessageVisible(true)} />}
      </AnnounceHintWrapper>
    ) : null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const triggerSubmit = useCallback(
    form.handleSubmit(handleSendMessage, () => {
      // Do not show validation errors for message
      form.clearErrors('message');
    }),
    [handleSendMessage, form],
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      triggerSubmit();
      form.setFocus('message');
    },
    [form, triggerSubmit],
  );

  const onKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      if (e.key !== 'Enter' || e.ctrlKey || e.shiftKey || e.metaKey) {
        return;
      }
      e.preventDefault();
      triggerSubmit();
    },
    [triggerSubmit],
  );

  return (
    <FormProvider {...form}>
      <SendForm onSubmit={onSubmit} ref={ref}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {tools}
          <FormField label="sms" name="sendCopyBySms" type="checkbox" />
        </Box>
        {!isMobile && attachment}
        <MessageInput disabled={!dialog?.id} form={form} onKeyDown={onKeyDown} />
        <Box sx={{ ml: isMobile ? 1.5 : 2.5 }}>
          <SendButton disabled={!message || !dialog?.id || isSendingMessage} size={isMobile ? 40 : 48} />
        </Box>
      </SendForm>
    </FormProvider>
  );
});
