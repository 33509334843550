import type { MouseEvent } from 'react';
import { SxProps } from '../../theme';

export type PaginationItemType = 'previous' | 'current' | 'page' | 'ellipsis' | 'next';

export type ItemsSeqMaxLengthResponsiveValues = Partial<Record<'mobile' | 'desktop', number>>;

export const paginationItemsSeqMaxLengthDict: ItemsSeqMaxLengthResponsiveValues = {
  desktop: 5,
  mobile: 3,
};

export interface PaginationItem {
  type: PaginationItemType;
  page: number;
  href?: string;
}

export interface PaginationProps {
  currentPage: number;
  lastPage: number;
  itemsSequenceMaxLength?: ItemsSeqMaxLengthResponsiveValues;
  pageLinkGenerator?: (page: number) => string;
  onChange: (event: MouseEvent, newPage: number, item: PaginationItem) => void;
  sx?: SxProps;
}
