import React, { PropsWithChildren, ReactNode } from 'react';
import { MOBILE_BREAKPOINT_THRESHOLD, SxProps } from '@cp/ds/src/theme';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { Box } from '@cp/ds/src/components/box';
import { Stack } from '@cp/ds/src/components/stack';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { ContentSkeleton } from '@cp/ds/src/components/contentSkeleton';
import { Typography } from '@cp/ds/src/components/typography';

export interface DialogListProps extends PropsWithChildren {
  isLoading: boolean;
  title?: ReactNode | ReactNode[];
  headerActions?: ReactNode | ReactNode[];
  pagination?: ReactNode;
  sx?: SxProps;
  listRootSx?: SxProps;
}

export const DialogList: React.FC<DialogListProps> = ({ isLoading, children, pagination, headerActions, title, sx, listRootSx }) => {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: { xs: 2.5, [MOBILE_BREAKPOINT_THRESHOLD]: 4 },
          position: 'relative',
        }}
      >
        {title && <Typography sx={{ typography: { xs: 'h5', [MOBILE_BREAKPOINT_THRESHOLD]: 'h1' } }}>{title}</Typography>}
        {headerActions && (
          <Stack direction="row" spacing={1}>
            {headerActions}
          </Stack>
        )}
      </Box>

      <Box>
        {isLoading ? (
          <ContentSkeleton blocks={3} />
        ) : Array.isArray(children) && children.length ? (
          <Box
            component="ul"
            sx={mergeSx(
              {
                borderTop: `1px solid ${theme.palette['gray middle']}`,
                marginBlockStart: 0,
                marginBlockEnd: 0,
                paddingInlineStart: 0,
              },
              listRootSx,
            )}
          >
            {children}
          </Box>
        ) : (
          <Box sx={{ py: 5 }}>
            <Typography component="div" sx={{ textAlign: 'center', color: 'gray' }} variant="body">
              Нет активных диалогов
            </Typography>
          </Box>
        )}
      </Box>
      {pagination}
    </Box>
  );
};
