import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { authenticationTokenPayloadSelector } from '@cp/shared/api/core';
import { User, UserType } from '../../user';

const initialState = { id: 0, type: UserType.Guest };

export const currentUserSelector = createDraftSafeSelector(authenticationTokenPayloadSelector, (payload): User => {
  if (payload?.clientId) {
    return {
      id: payload.clientId,
      type: UserType.Client,
    };
  }
  if (payload?.adminId) {
    return {
      id: payload.adminId,
      type: UserType.Admin,
    };
  }
  return initialState;
});
