import { useEffect } from 'react';
import { useRouter } from '@cp/shared/router';
import { AdminRoutePath } from 'src/app/routes/model';
import { useAdminRouteResolver } from 'src/entities/adminRoute';
import { DialogViewerProps } from './viewer.interface';

export const RedirectViewer = ({ dialogId }: DialogViewerProps) => {
  const router = useRouter();
  const pathResolver = useAdminRouteResolver();
  useEffect(() => {
    if (dialogId) {
      router.navigate(pathResolver.resolve({ route: AdminRoutePath.Messages, params: { dialogId } }));
    }
  }, [router, pathResolver, dialogId]);
  return null;
};
