import { encodeQueryParams } from '@cp/shared/queryParams';
import { IResolver } from '@cp/shared/resolver';
import { generatePath, matchPath } from 'react-router';
import { AdminRoutePath } from './routes';

export type AdminRouteResource = {
  route: AdminRoutePath;
  params?: Record<string, string | number | undefined>;
};

export class AdminRouteResolver implements IResolver<AdminRouteResource> {
  resolve(resource: AdminRouteResource) {
    const path = generatePath(resource.route, resource.params);
    const match = matchPath(path, { path: resource.route, exact: true });
    const routeProps = Object.keys(match && match.params ? match.params : {}) as string[];
    const queryEntries = Object.entries(resource.params || {}).filter(([key]) => !routeProps.includes(key));
    if (!queryEntries.length) {
      return path;
    }
    return `${path}?${encodeQueryParams(Object.fromEntries(queryEntries))}`;
  }
}
