import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MetaPageView } from '@common/model/meta';
import { adminRoutesApi } from 'src/widgets/routesTable/api';
import { TempRoute, slice } from './routesTable.slice';

export const useDeleteRoute = () => {
  const dispatch = useDispatch();
  const [doDelete] = adminRoutesApi.endpoints.deletePage.useMutation();
  return useCallback(
    (route: TempRoute | MetaPageView) => {
      if ('id' in route) {
        if (window.confirm('Удалить роут?')) {
          return doDelete(route.id).unwrap();
        }
        return Promise.reject(new Error('Operation cancelled'));
      }
      dispatch(slice.actions.deletePage(route));
      return Promise.resolve();
    },
    [dispatch, doDelete],
  );
};
