import { FeedbackViewModel } from '@common/model/catalog/feedback/view';

export const buildFeedbacksSchema = (parameters: {
  productName: string;
  lowPrice?: number;
  averageRating: number;
  totalCount: number;
  items: FeedbackViewModel[];
}) => {
  const { productName, lowPrice = 100 /* ToDo: use real value */, averageRating, totalCount, items } = parameters;

  return {
    '@type': 'Product',
    name: productName,
    offers: {
      '@type': 'AggregateOffer',
      priceCurrency: 'RUB',
      lowPrice,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      worstRating: 1,
      bestRating: 5,
      ratingValue: averageRating,
      reviewCount: totalCount,
    },
    review: items.map(({ rating, authorName, createdAt, review }) => {
      const reviewSchema = {
        '@type': 'Review',
        datePublished: createdAt,
        author: {
          '@type': 'Person',
          name: authorName,
        },
        reviewRating: {
          '@type': 'Rating',
          bestRating: 5,
          ratingValue: rating,
          worstRating: 1,
        },
      };

      if (review) {
        (reviewSchema as typeof reviewSchema & { reviewBody?: string }).reviewBody = review?.text;
      }

      return reviewSchema;
    }),
  };
};
