import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const DeleteIcon = React.forwardRef<SVGSVGElement, Omit<SvgIconProps, 'cacheName'>>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="delete" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M4.04297 8.5C4.04297 8.08579 4.37876 7.75 4.79297 7.75H19.2068C19.621 7.75 19.9568 8.08579 19.9568 8.5C19.9568 8.91421 19.621 9.25 19.2068 9.25H4.79297C4.37876 9.25 4.04297 8.91421 4.04297 8.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.3994 4.97314C10.1666 4.97314 9.94763 5.06316 9.7895 5.21675C9.63211 5.36962 9.54787 5.57221 9.54787 5.77869V7.75089H14.454V5.77869C14.454 5.57221 14.3698 5.36962 14.2124 5.21675C14.0542 5.06316 13.8352 4.97314 13.6025 4.97314H10.3994ZM15.954 7.75089V5.77869C15.954 5.16005 15.7008 4.57133 15.2575 4.14075C14.8149 3.7109 14.2192 3.47314 13.6025 3.47314H10.3994C9.78268 3.47314 9.18696 3.7109 8.7444 4.14075C8.30109 4.57133 8.04787 5.16005 8.04787 5.77869V7.75089H6.39557C6.186 7.75089 5.98599 7.83858 5.84398 7.99271C5.70198 8.14684 5.63095 8.35335 5.64808 8.56222L6.44346 18.2568C6.4525 18.8631 6.70451 19.4383 7.13974 19.861C7.5823 20.2908 8.17801 20.5286 8.79474 20.5286H15.2009C15.8176 20.5286 16.4133 20.2908 16.8559 19.861C17.291 19.4383 17.543 18.8633 17.5522 18.2571L18.3538 8.5627C18.371 8.35375 18.3001 8.14712 18.1581 7.99288C18.016 7.83865 17.816 7.75089 17.6063 7.75089H15.954ZM7.20962 9.25089L7.9407 18.1617C7.94237 18.1821 7.94321 18.2026 7.94321 18.223C7.94321 18.4295 8.02745 18.6321 8.18484 18.785C8.34297 18.9386 8.56197 19.0286 8.79474 19.0286H15.2009C15.4337 19.0286 15.6527 18.9386 15.8108 18.785C15.9682 18.6321 16.0524 18.4295 16.0524 18.223C16.0524 18.2024 16.0533 18.1818 16.055 18.1612L16.7917 9.25089H7.20962Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.7694 11.9451C14.1823 11.9779 14.4904 12.3393 14.4576 12.7522L14.1955 16.0525C14.1627 16.4655 13.8013 16.7736 13.3884 16.7408C12.9755 16.708 12.6674 16.3467 12.7002 15.9338L12.9623 12.6334C12.9951 12.2205 13.3565 11.9123 13.7694 11.9451Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.2306 11.9451C9.81771 11.9779 9.50957 12.3393 9.54237 12.7522L9.80453 16.0525C9.83733 16.4655 10.1987 16.7736 10.6116 16.7408C11.0245 16.708 11.3326 16.3467 11.2998 15.9338L11.0377 12.6334C11.0049 12.2205 10.6435 11.9123 10.2306 11.9451Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
