export const DEFAULT_CATALOG_PAGE_NUMBER = 1;
export const DEFAULT_CATALOG_PER_PAGE = 40;
export const DEFAULT_SUBCATALOG_PER_PAGE = 16;
export const CLIENT_CATALOG_ITEM_PAGINATION_SIZE_MAX = 100;
export const CATALOG_MIN_ITEMS_COUNT_FOR_INDEXING = 2;
export const CLIENT_CATALOG_REVIEW_PAGINATION_SIZE_DEFAULT = 3;
export const CLIENT_CATALOG_REVIEW_PAGINATION_SIZE_MAX = 100;

export const newCareersDict = {
  5: {
    isActive: false,
    translation: { namePlural: 'Психологи' },
  },
  6: {
    isActive: false,
    translation: { namePlural: 'Зооняни' },
  },
} satisfies {
  [careerId: number]: {
    isActive: false;
    translation: {
      namePlural: string;
    };
  };
};

export const newCareers = Object.keys(newCareersDict).map(Number);
