import { coreApi } from '@cp/shared/api/core';
import { ClientsResponse } from '@common/model/admin/clients/clients.response';
import { ClientsQuery } from '@common/model/admin/clients/clients.query';
import { AdminClientProfileViewModel } from '@common/model/client/profile/adminClientProfileViewModel';
import { AdminCatalogItemApiTag } from 'src/entities/catalog/item';

export const coreClientApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<ClientsResponse, ClientsQuery>({
      query: (params) => ({
        url: 'admin/clients',
        method: 'get',
        params,
      }),
    }),

    getClient: builder.query<AdminClientProfileViewModel, { id: number }>({
      query: ({ id }) => ({
        url: `admin/clients/${id}/profile`,
        method: 'get',
      }),
    }),

    deleteClient: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `admin/clients/${id}`,
        method: 'delete',
      }),
    }),

    toggleClientDocumentsCheck: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `admin/clients/${id}/documentsCheck`,
        method: 'put',
      }),
      invalidatesTags: [AdminCatalogItemApiTag.Item],
    }),
  }),
});
