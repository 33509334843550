import { ClientInteractionDetails } from '@common/model/stats/ClientInteractionDetails';
import { ClientType } from '@common/model/client/clientType';
import { User, UserType } from '@cp/entities/user';

export const getUserFromInteraction = (type: ClientType, m: ClientInteractionDetails): User | null => {
  const { initiator, target } = m;
  if (initiator?.clientType === type) {
    return {
      id: initiator.id,
      type: UserType.Client,
    };
  }
  if (target?.clientType === type) {
    return {
      id: target.id,
      type: UserType.Client,
    };
  }
  return null;
};
