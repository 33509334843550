import { generateDraftId } from '@cp/entities/dialog';
import { DialogMessageCreateRequest } from '@common/model/dialog/message/dialogMessageCreateRequest';
import { MessageType } from '@common/model/dialog/message/messageType';
import { MessageFormValues } from './messageForm';

export const generateCreateMessageRequest = (formValues: MessageFormValues): DialogMessageCreateRequest => {
  return {
    type: MessageType.Text,
    payload: formValues.message,
    dialogId: formValues.dialogId,
    draftId: generateDraftId(formValues.dialogId, formValues.message, MessageType.Text),
    sendCopyBySms: formValues.sendCopyBySms ? 1 : undefined,
    asClient: formValues.asClient,
  };
};
