import { coreApi } from '@cp/shared/api/core';
import { AdminCreatePageRequest, AdminGetPagesQuery, AdminGetPagesResponse, MetaPageView } from '@common/model/meta';

enum AdminRoutesApiTags {
  List = 'List',
}

export const adminRoutesApi = coreApi.enhanceEndpoints({ addTagTypes: Object.values(AdminRoutesApiTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getPages: builder.query<AdminGetPagesResponse, AdminGetPagesQuery>({
      query: (params) => ({
        url: 'meta/pages',
        params,
      }),
      providesTags: [AdminRoutesApiTags.List],
    }),
    getOne: builder.query<MetaPageView, number>({
      query: (id) => ({
        url: `meta/pages/${id}`,
      }),
    }),
    updatePage: builder.mutation<MetaPageView, MetaPageView>({
      query: (body) => ({
        url: `meta/pages/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    createPage: builder.mutation<MetaPageView, AdminCreatePageRequest>({
      query: (body) => ({
        url: 'meta/pages',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [AdminRoutesApiTags.List],
    }),
    deletePage: builder.mutation<void, number>({
      query: (id) => ({
        url: `meta/pages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [AdminRoutesApiTags.List],
    }),
  }),
});
