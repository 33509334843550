import { createContext, useContext } from 'react';
import { ICurrentUserProvider } from './ports';
const ctx = createContext<ICurrentUserProvider>({
  getUser() {
    throw new Error('CurrentUserContext is empty');
  },
});

export const DialogUserContextProvider = ctx.Provider;

export const useCurrentUserContext = () => useContext(ctx);
