import React from 'react';
import {
  DialogMessageNotification,
  DialogMessageRenderer,
  DialogMessageText,
  MessageWithActionsLayout,
  useCurrentDialogMember,
  useDialogTheme,
} from '@cp/entities/dialog';
import { MessageType } from '@common/model/dialog/message/messageType';
import { Typography } from '@cp/ds/src/components/typography';
import { Stack } from '@cp/ds/src/components/stack';
import { Button } from '@cp/ds/src/components/button';
import { dialogMembersSelector } from '@cp/entities/dialog/model/store/selectors';
import { EntityId } from '@reduxjs/toolkit';
import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { useSelector } from 'react-redux';
import { DialogReducerStateShape } from '@cp/entities/dialog/model/store/dialog';

export const FavoriteNotificationRenderer: DialogMessageRenderer<MessageType.NotificationFavorite> = (props) => {
  const { message, dialogId } = props;
  const currentMember = useCurrentDialogMember({ dialogId });
  const isCurrentUserSubject = currentMember?.entityId === message.payload.subjectClientId;
  const members = useSelector((state) => dialogMembersSelector(state as DialogReducerStateShape, dialogId as EntityId));
  const favoriteMember = members.find((m) => m.entityId === message.payload.subjectClientId);
  const { listGap } = useDialogTheme();

  if (message.author.type === DialogMemberType.Bot) {
    return <DialogMessageNotification {...props} text="Вы добавлены в список избранных" />;
  }

  return (
    <Stack gap={listGap}>
      <DialogMessageText
        {...props}
        message={{ ...message, payload: `Здравствуйте${favoriteMember?.name ? ` ${favoriteMember.name}` : ''}, вы еще работаете няней? ` }}
      />

      {isCurrentUserSubject && (
        <MessageWithActionsLayout>
          <Typography variant="body bold">Нанимателю понравилась ваша анкета, он ждет ответ</Typography>
          <Stack alignItems="center" direction="row" spacing={1} sx={{ mt: 1, width: 1 }}>
            <Button color="gray" disabled size="extraSmall" sx={{ flex: '1 1 0px' }} variant="primary">
              Да, работаю
            </Button>
            <Button color="pink" disabled size="extraSmall" sx={{ flex: '1 1 0px' }} variant="primary">
              Нет
            </Button>
          </Stack>
        </MessageWithActionsLayout>
      )}
    </Stack>
  );
};
