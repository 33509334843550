import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User, UserType } from '@cp/entities/user';

const name = 'dialogUser';
const guest = { id: 0, type: UserType.Guest };
export type SliceShape = { [name]: User };

export const slice = createSlice({
  name,
  initialState: guest,
  reducers: {
    setDialogUser: (_, action: PayloadAction<User>) => {
      return action.payload;
    },
    resetDialogUser: () => {
      return guest;
    },
  },
});

export const selectDialogUser = (state: SliceShape) => state.dialogUser;

export const reducerConfig = {
  [name]: slice.reducer,
};
