import React, { useCallback, useEffect } from 'react';
import { DialogUserContextProvider, ICurrentUserProvider, currentDialogModel, dialogModel } from '@cp/entities/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { DialogViewerProps, useDialogUserService } from 'src/features/dialog/view';
import { DialogDrawer } from './DialogDrawer';

export const DialogViewer = ({ dialogId, onClose }: DialogViewerProps) => {
  const dispatch = useDispatch();
  const currentDialogId = useSelector(currentDialogModel.selectors.currentDialogIdSelector);
  const userProvider: ICurrentUserProvider = useDialogUserService();

  useEffect(() => {
    if (dialogId && currentDialogId !== dialogId) {
      dispatch(dialogModel.actions.switchToId({ dialogId, currentUser: userProvider.getUser() }));
      return;
    }
    if (currentDialogId && !dialogId) {
      dispatch(dialogModel.actions.reset());
      return;
    }
  }, [dialogId, currentDialogId, dispatch, userProvider]);

  const handleDialogClose = useCallback(() => {
    dispatch(dialogModel.actions.reset());
    onClose?.();
  }, [onClose, dispatch]);

  return (
    <DialogUserContextProvider value={userProvider}>
      <DialogDrawer handleDialogClose={handleDialogClose} />
    </DialogUserContextProvider>
  );
};
