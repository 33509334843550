import { EntityId, createSelector } from '@reduxjs/toolkit';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { getAllDialogMembersForUser, getDialogMemberForUser, getInitialParticipant } from '../../lib';
import { User } from '../../../user';
import { DialogReducerStateShape, DialogState } from './dialog';
import { dialogsAdapter } from './dialogsAdapter';
import { membersAdapter } from './membersAdapter';
import { messagesAdapter } from './messagesAdapter';

const { selectById: selectDialogById, selectIds: selectDialogIds } = dialogsAdapter.getSelectors();
const { selectById: selectMemberById, selectAll: selectAllMembers } = membersAdapter.getSelectors();
const { selectEntities: selectMessageEntities } = messagesAdapter.getSelectors();

export type UserToDialogParams = { user: User; dialogId: EntityId };
const userToDialogParamsFn = (_: unknown, params: UserToDialogParams) => params;
const entityIdFn = (_: unknown, id: EntityId) => id;
const dialogStateSelector = (state: DialogReducerStateShape) => state.dialog;

const selectDialogMembers = (state: DialogState, dialogId: EntityId) => {
  const dialog = selectDialogById(state.dialogs, dialogId);
  const members: DialogMemberViewModel[] = [];
  if (!dialog) {
    return members;
  }
  let member: DialogMemberViewModel | undefined;
  for (const memberId of dialog.members) {
    member = selectMemberById(state.members, memberId);
    if (!member) {
      continue;
    }
    members.push(member);
  }
  return members;
};

const selectInitialParticipant = (state: DialogState, params: UserToDialogParams) => {
  const members = selectDialogMembers(state, params.dialogId);
  return getInitialParticipant(members, params.user);
};

const selectCurrentMember = (state: DialogState, params: UserToDialogParams) => {
  const members = selectDialogMembers(state, params.dialogId);
  return getDialogMemberForUser(members, params.user);
};

const selectUnreadMessagesCountInDialog = (state: DialogState, params: UserToDialogParams): number => {
  const member = selectCurrentMember(state, params);
  return member?.unreadMessagesCount || 0;
};

export const unreadMessagesTotalCountSelector = createSelector(
  [(state: DialogReducerStateShape) => selectAllMembers(state.dialog.members), (_: unknown, user: User) => user],
  (allMembers, user) => {
    const members = getAllDialogMembersForUser(allMembers, user);
    return members.reduce((totalCount: number, member) => {
      const countInDialog: number = member.unreadMessagesCount || 0;
      return totalCount + countInDialog;
    }, 0);
  },
);

export const unreadMessagesCountInDialogSelector = createSelector([dialogStateSelector, userToDialogParamsFn], (state, params) =>
  selectUnreadMessagesCountInDialog(state, params),
);

export const dialogMembersSelector = createSelector([dialogStateSelector, entityIdFn], (state, dialogId) =>
  selectDialogMembers(state, dialogId),
);

export const initialParticipantMemberSelector = createSelector([dialogStateSelector, userToDialogParamsFn], (state, params) =>
  selectInitialParticipant(state, params),
);

export const currentDialogMemberSelector = createSelector([dialogStateSelector, userToDialogParamsFn], (state, params) =>
  selectCurrentMember(state, params),
);

export const memberByIdSelector = createSelector([dialogStateSelector, entityIdFn], (state, memberId) =>
  selectMemberById(state.members, memberId),
);

export const dialogsIdsSelector = createSelector((state: DialogReducerStateShape) => state.dialog.dialogs, selectDialogIds);

export const dialogByIdSelector = createSelector([dialogStateSelector, entityIdFn], (state, dialogId) =>
  selectDialogById(state.dialogs, dialogId),
);

export const dialogListCurrentPageSelector = (state: DialogReducerStateShape) => state.dialog.dialogsPage;

export const dialogListTotalPagesSelector = createSelector([dialogStateSelector], (state) =>
  Math.ceil(state.totalDialogs / state.dialogsPerPage),
);

export const dialogListPaginationSelector = createSelector([dialogStateSelector], (state) => state.dialogsPerPage);

export const dialogLastMessageSelector = createSelector([dialogStateSelector, entityIdFn], (state, dialogId) => {
  const dialog = selectDialogById(state.dialogs, dialogId);
  return dialog?.lastMessage;
});

export const messagesDictSelector = createSelector([dialogStateSelector], (state) => selectMessageEntities(state.current.messages));
