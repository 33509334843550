import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Link } from '@cp/ds/src/components/link';
import { ClientContactType } from '@common/model/client/clientContactType';
import { clientContactItemDict } from '../item/dict';
import { PreviewProps } from './model';

export * from './model';

export const Preview: React.FC<PreviewProps> = ({ data = {}, contactsToHide, contactsToShow, interactive, hideEmpty, ...props }) => {
  const theme = useTheme();

  if (contactsToHide && contactsToShow) {
    console.error(
      '[ClientContactsPreview]: `contactsToHide` and `contactsToShow` props specified at same time. You should use only one of them.',
    );
    return null;
  }

  const shouldShowContact = (contactType: ClientContactType): boolean => {
    if (contactsToHide) {
      return !contactsToHide.includes(contactType);
    }
    if (contactsToShow) {
      return contactsToShow.includes(contactType);
    }
    return true;
  };

  return (
    <Stack direction="row" flexWrap="wrap" gap={1} sx={{ mt: { xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 2 } }} {...props}>
      {Object.entries(clientContactItemDict).map(([type, dictItem]) => {
        if (!dictItem || !shouldShowContact(parseInt(String(type))) || (hideEmpty && !data[type])) {
          return null;
        }

        const content = <dictItem.icon color={!data[type] ? theme.palette['gray middle'] : undefined} sx={{ fontSize: '2.5rem' }} />;

        if (interactive) {
          return (
            <Link href={dictItem.linkResolver({ value: String(data[type]) })} key={type} underline={false}>
              {content}
            </Link>
          );
        } else {
          return <React.Fragment key={type}>{content}</React.Fragment>;
        }
      })}
    </Stack>
  );
};
