import { useCallback, useState } from 'react';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { User } from '@cp/entities/user';
import { coreDialogApi, mapUserTypeToMemberType } from '@cp/entities/dialog';
import { useCurrentUser } from '@cp/entities/currentUser';
import { IDialogFindQuery } from './findDialog.interface';
import { useDialogIdGetter } from './useDialogIdGetter';
import { useDialogUserService } from './useDialogUserService';

export const useOpenDialog = () => {
  const [getDialogById] = coreDialogApi.endpoints.getDialog.useLazyQuery();
  const [joinDialog] = coreDialogApi.endpoints.joinDialog.useMutation();
  const [getDialogId, isLoading] = useDialogIdGetter();
  const [dialogId, setActiveDialogId] = useState<ApiServerEntityIDType | undefined>();
  const service = useDialogUserService();
  const currentUser = useCurrentUser();
  const openDialog = useCallback(
    async (dialogFindQuery: IDialogFindQuery, as?: User) => {
      // eslint-disable-next-line no-unused-expressions
      typeof as !== 'undefined' ? service.setUser(as) : service.reset();
      const id = await getDialogId(dialogFindQuery);
      if (!id) {
        console.debug('Can not open dialog by query', dialogFindQuery);
        return;
      }
      setActiveDialogId(id);
      const dialog = await getDialogById({ id }).unwrap();
      const isMember =
        dialog.members.findIndex((m) => m.entityId === currentUser.id && m.type === mapUserTypeToMemberType(currentUser.type)) !== -1;
      if (!isMember) {
        console.info('Join current user to dialog');
        await joinDialog({ dialogId: id });
      }
    },
    [getDialogId, service, joinDialog, getDialogById, currentUser],
  );

  const onClose = useCallback(() => {
    setActiveDialogId(undefined);
    service.reset();
  }, [service]);
  return { isLoading, openDialog, dialogId, onClose };
};
