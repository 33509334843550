import React, { useCallback } from 'react';
import { Button } from '@cp/ds/src/components/button';
import { coreDialogApi, currentDialogModel } from '@cp/entities/dialog';
import { MessageType } from '@common/model/dialog/message/messageType';
import { DialogMessageCreateRequest } from '@common/model/dialog/message/dialogMessageCreateRequest';
import { useSelector } from 'react-redux';
import { ClientType } from '@common/model/client/clientType';
import { ClientContactType } from '@common/model/client/clientContactType';
import { useDialogUserService } from 'src/features/dialog/view';
import { useClientProfile } from 'src/entities/clientProfile';

export const ContactsPayButton = () => {
  const dialogId = useSelector(currentDialogModel.selectors.currentDialogIdSelector);
  const dialogUserService = useDialogUserService();
  const [sendMessage, { isLoading }] = coreDialogApi.endpoints.sendMessage.useMutation();
  const user = dialogUserService.getUser();
  const { data: client } = useClientProfile(user.id);

  const onClick = useCallback(async () => {
    if (!dialogId) {
      return;
    }
    const req: DialogMessageCreateRequest<MessageType.ClientContacts> = {
      type: MessageType.ClientContacts,
      dialogId: Number(dialogId),
      payload: { contactTypes: [ClientContactType.Phone, ClientContactType.Telegram, ClientContactType.WhatsApp] },
      asClient: user.id,
    };
    const result = await sendMessage(req);
  }, [dialogId, sendMessage, user.id]);

  return (
    <Button disabled={client?.type !== ClientType.Employer || isLoading} onClick={onClick} size="small" type="button" variant="secondary">
      ₽
    </Button>
  );
};
