import React, { useCallback } from 'react';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@cp/ds/src/components/button';
import { Box } from '@cp/ds/src/components/box';
import { catalogOptionCoreApi } from '@cp/entities/catalogOption';
import { CheckableAdvantage } from '@cp/entities/advantage';
import { FormField } from '@cp/ds/src/components/form';
import { CheckboxGroupItemConfig } from '@cp/ds/src/components/form/components/field/checkboxGroup/model';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { MOBILE_BREAKPOINT_THRESHOLD, SxProps } from '@cp/ds/src/theme';
import { useApiServerErrorHandler } from 'src/app/shared/hooks/useApiServerErrorHandler';
import { adminResumeApi } from 'src/entities/resume';

const obj2arr = (obj: Record<string, boolean>): string[] => {
  return Object.keys(obj).filter((key) => obj[key]);
};
const arr2obj = (arr: number[]) => {
  return arr.reduce((acc, v) => {
    acc[v] = true;
    return acc;
  }, {});
};

export interface OptionsFormProps {
  data: CatalogItemViewModel;
  onComplete?: () => void;
  sx?: SxProps;
}
type FormValues = { options: ApiServerEntityIDType[] };

export const OptionsForm: React.FC<OptionsFormProps> = ({ data, sx, onComplete }) => {
  const errorHandler = useApiServerErrorHandler();
  const { data: allOptions } = catalogOptionCoreApi.endpoints.getOptions.useQuery({ careerId: data.careerId, active: true });
  const [updateResume] = adminResumeApi.endpoints.updateResumeById.useMutation();
  const form = useForm<FormValues>({
    values: { options: data.options.map((option) => option.id) },
  });
  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!allOptions) {
        return;
      }

      // NOTE: Some options selected by client can be inactive for now, so we should use only active for now options.
      // `allOptions` always contains relevant options list with active options.
      const optionIds = values.options.filter((id) => allOptions.items.some((option) => option.id === id));

      const result = await updateResume({ params: { id: data.id }, body: { careerId: data.careerId, optionIds } });
      if ('error' in result) {
        errorHandler(result.error, { form });
      } else {
        onComplete?.();
      }
    },
    [allOptions, updateResume, data.id, data.careerId, errorHandler, form, onComplete],
  );
  const items: CheckboxGroupItemConfig<number>[] = allOptions
    ? allOptions.items.map(
        (option): CheckboxGroupItemConfig<number> => ({
          value: option.id,
          label: option.translation.title,
          icon: option.icon?.value,
        }),
      )
    : [];
  return (
    <FormProvider {...form}>
      <Box
        component="form"
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          alignItems: { xs: 'stretch', [MOBILE_BREAKPOINT_THRESHOLD]: 'center' },
          mb: { xs: 5, [MOBILE_BREAKPOINT_THRESHOLD]: 'inherit' },
          ...sx,
        }}
      >
        <FormField
          checkboxComponent={CheckableAdvantage}
          containerProps={{
            sx: {
              m: 0,
              maxWidth: '100%',
              display: 'grid',
              gap: 1.5,
              gridTemplateColumns: { xs: '1fr', [MOBILE_BREAKPOINT_THRESHOLD]: '1fr 1fr' },
            },
          }}
          itemSx={{ padding: '0!important' }}
          items={items}
          name="options"
          transform={{
            in: (v: unknown) => {
              return obj2arr(v as Record<string, boolean>).map(Number);
            },
            out: (v: unknown) => {
              return arr2obj(v as number[]);
            },
          }}
          type="checkboxGroup"
        />
        <Button color="pink" disabled={!allOptions} type="submit">
          Сохранить
        </Button>
      </Box>
    </FormProvider>
  );
};
