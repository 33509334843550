import React, { useRef } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { MessageContent } from '@cp/ds/src/components/messageContent';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { MessageType } from '@common/model/dialog/message/messageType';
import { DialogMessageRenderer, isMessageAuthor } from '../../lib';
import { useCurrentDialogMember } from '../../model';
import { useReadNotification } from '../../model/useReadNotification';
import { MessageDelivery } from '../messageDelivery';
import { MessageDate } from '../mesageDate';

export const DialogMessageText: DialogMessageRenderer<MessageType.Text> = ({ message, isPreview }) => {
  const theme = useTheme();
  const currentMember = useCurrentDialogMember();
  const { payload } = message;
  const isSelfMessage = isMessageAuthor(message, currentMember);
  const ref = useRef<HTMLDivElement>(null);
  useReadNotification(ref, message);

  if (isPreview) {
    return <MessageContent content={payload} textOnly={isPreview} />;
  }

  return (
    <Box
      id={message.draftId}
      ref={ref}
      sx={{
        ml: isSelfMessage ? 'auto' : 0,
        mr: isSelfMessage ? 0 : 'auto',
        maxWidth: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        p: '8px 6px 4px 12px ',
        backgroundColor: theme.palette[isSelfMessage ? 'gray' : 'white'],
        border: isSelfMessage ? 'none' : `1px solid ${theme.palette['gray light']}`,
        borderRadius: isSelfMessage ? '12px 0px 12px 12px' : '0px 12px 12px 12px',
        color: isSelfMessage ? theme.palette.white : 'inherit',
      }}
    >
      <Typography variant="caption">
        <MessageContent content={payload} /*onOpenModal={openModal as MessageContentProps['onOpenModal']}*/ />
      </Typography>
      <Typography
        component="footer"
        sx={{
          opacity: '0.6',
          mt: isSelfMessage ? 0.5 : 0,
          ml: isSelfMessage ? 0 : 1.5,
          gap: '2px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'end',
        }}
        variant="extraSmall"
      >
        <MessageDelivery message={message} />
        <MessageDate message={message} />
      </Typography>
    </Box>
  );
};
