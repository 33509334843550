import React from 'react';
import { DialogMessageNotification, DialogMessageRenderer, useCurrentDialogMember } from '@cp/entities/dialog';
import { MessageType } from '@common/model/dialog/message/messageType';

export const UnFavoriteNotificationRenderer: DialogMessageRenderer<MessageType.NotificationUnFavorite> = (props) => {
  const { dialogId } = props;
  const currentMember = useCurrentDialogMember({ dialogId });
  const isCurrentUserSubject = currentMember?.entityId === props.message.payload.subjectClientId;
  return isCurrentUserSubject ? <DialogMessageNotification {...props} text="Вы удалены из списка избранных" /> : null;
};
