import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '../../hooks/useTheme';
import { Box } from '../box';
import { TRANSITION_DURATION, Z_INDEX } from './const';
import { ModalViewBaseProps } from './model';

export const FullscreenView: React.FC<ModalViewBaseProps> = ({ children, onClose, isOpen, modalProps }) => {
  const theme = useTheme();
  return (
    <Dialog
      {...modalProps}
      PaperComponent={Box}
      PaperProps={{
        sx: [
          {
            m: 0,
            maxHeight: '100%',
            maxWidth: '100% !important',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.white,
          },
        ],
      }}
      TransitionProps={{ onExited: onClose }}
      disableScrollLock
      onClose={onClose}
      open={isOpen}
      sx={{ zIndex: Z_INDEX }}
      transitionDuration={TRANSITION_DURATION}
    >
      {children}
    </Dialog>
  );
};

export default FullscreenView;
