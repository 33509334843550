import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { SxProps } from '@cp/ds';
import { EntityId } from '@reduxjs/toolkit';
import { DialogMessageBlockPlain } from '../../model/dialogMessageDateBlock';
import { DialogMessagesDateBlock } from '../dialogMessagesDateBlock';
import { useDialogTheme } from '../theme';

export interface DialogMessageListProps {
  dialogId: EntityId;
  blocks: DialogMessageBlockPlain[];
  sx?: SxProps;
}

export const DialogMessageList = (props: DialogMessageListProps) => {
  const dialogTheme = useDialogTheme();
  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: dialogTheme.listGap, ...props.sx }}>
      {props.blocks.map((block, index) => (
        <DialogMessagesDateBlock block={block} dialogId={props.dialogId} isLast={index === props.blocks.length - 1} key={block.title} />
      ))}
    </Box>
  );
};
