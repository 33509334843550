import { useCurrentUser } from '@cp/entities/currentUser';
import { useMemo } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { DialogUserService } from './dialogUser.service';
import { SliceShape, selectDialogUser, slice } from './dialogUser.slice';

export const useDialogUserService = (): DialogUserService => {
  const cu = useCurrentUser();
  const store = useStore<SliceShape>();
  const dispatch = useDispatch();
  return useMemo(
    () =>
      new DialogUserService(
        {
          get: () => selectDialogUser(store.getState()),
          set: (u) => dispatch(slice.actions.setDialogUser(u)),
          reset: () => dispatch(slice.actions.resetDialogUser()),
        },
        () => cu,
      ),
    [cu, dispatch, store],
  );
};
