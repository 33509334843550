import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { SxProps } from '@cp/ds';
import { EntityId } from '@reduxjs/toolkit';
import { DialogMessageBlockPlain } from '../../model/dialogMessageDateBlock';
import { DialogContextType, MessageComponentProps, useDialogContext } from '../context';
import { useDialogTheme } from '../theme';

const MessageComponentWithDataResolver: React.FC<
  Pick<DialogContextType, 'MessageComponent' | 'useMessageResolver'> & { messageId: EntityId } & Omit<MessageComponentProps, 'message'>
> = ({ MessageComponent, useMessageResolver, messageId, ...messageComponentProps }) => {
  const message = useMessageResolver({ id: messageId });

  if (!message) {
    return null;
  }

  return <MessageComponent message={message} {...messageComponentProps} />;
};

export interface DialogMessagesDateBlockProps {
  dialogId: EntityId;
  block: DialogMessageBlockPlain;
  sx?: SxProps;
  isLast: boolean;
}
export const DialogMessagesDateBlock = ({ dialogId, block, sx, isLast }: DialogMessagesDateBlockProps) => {
  const dialogTheme = useDialogTheme();
  const { MessageComponent, MessageListBottom, useMessageResolver } = useDialogContext();

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: dialogTheme.listGap, ...sx }}>
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            zIndex: 1,
            width: 1,
            height: '1px',
            backgroundColor: dialogTheme.blockHeaderLine,
          }}
        />
        <Typography
          sx={{
            position: 'relative',
            zIndex: 2,
            display: 'inline-block',
            px: 2,
            color: dialogTheme.blockHeaderText,
            backgroundColor: dialogTheme.listBg,
          }}
          variant="caption"
        >
          {block.title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: dialogTheme.listGap }}>
        {block.messages.map((messageId) => {
          return (
            <MessageComponentWithDataResolver
              MessageComponent={MessageComponent}
              dialogId={dialogId}
              key={messageId}
              messageId={messageId}
              useMessageResolver={useMessageResolver}
            />
          );
        })}
        {isLast && MessageListBottom && <MessageListBottom dialogId={dialogId} lastMessageId={block.messages[block.messages.length - 1]} />}
      </Box>
    </Box>
  );
};
