import { ClientInteractionsRequest } from '@common/model/stats/clientInteractions.request';
import { InteractionType } from '@common/model/stats/interactionType';
import { ClientInteractionViewModel } from '@common/model/stats/clientInteractionViewModel';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { calcActualThresholdIdx, calcTimeout, getThresholdRelativeDateRange } from 'src/features/responseDelay';
import { IResponseDelayApiProvider, IResponseDelayStorage } from '../lib';
import { Threshold } from './thresholds';

export class ResponseDelayService {
  #thresholds: Threshold[];
  #timers = new Map<number, TimeoutId>();

  constructor(
    private readonly api: IResponseDelayApiProvider,
    private readonly storage: IResponseDelayStorage,
    private readonly _thresholds: Threshold[],
  ) {
    this.#thresholds = _thresholds.slice();
    this.loadItems();
    this.api.onItemAdded(this.onItemCreatedOnServer.bind(this));
    this.api.onItemDeleted(this.onItemDeletedOnServer.bind(this));
  }

  get thresholds() {
    return this.#thresholds;
  }

  tearDown() {
    for (const item of this.#timers) {
      clearTimeout(item[1]);
    }
    this.storage.clear();
  }

  getCachedThresholdItems(threshold: Threshold): ClientInteractionViewModel[] {
    return this.storage.getThresholdItems(threshold);
  }

  hasMoreForThreshold(threshold: Threshold): boolean {
    const { totalCount, loadedCount } = this.storage.getPagination(threshold);
    return loadedCount < totalCount;
  }

  async loadMore(threshold: Threshold) {
    const { page } = this.storage.getPagination(threshold);
    await this.loadThresholdItems(threshold, page + 1);
  }

  private buildRequest(threshold: Threshold, page = 1): ClientInteractionsRequest {
    const range = getThresholdRelativeDateRange(this._thresholds, threshold, this.storage.getBaseDate());
    return {
      type: InteractionType.DialogEstablishing,
      page,
      pagination: 100,
      completedAt: { isEmpty: true },
      notBefore: range[0],
      notLater: range[1],
    };
  }

  private async loadThresholdItems(threshold: Threshold, page?: number) {
    const params = this.buildRequest(threshold, page);
    const { items, totalCount } = await this.api.getItems(params);
    if (totalCount) {
      this.storage.fillThreshold(threshold, items, totalCount);
      this.storage.setPagination(threshold, { page, totalCount });
      items.map((item) => this.setItemTimer(item));
    }
  }

  private setItemTimer(item: ClientInteractionViewModel) {
    const thresholds = this._thresholds;
    const timeLeft = calcTimeout(thresholds, new Date(item.waitingFrom));
    if (!timeLeft) {
      // this.clearItemTimer(item);
      return;
    }
    const timeout = setTimeout(() => {
      const i = calcActualThresholdIdx(thresholds, new Date(item.waitingFrom));
      this.storage.moveToThreshold(thresholds[i], item);
      this.setItemTimer(item);
    }, timeLeft);
    this.#timers.set(item.id, timeout);
  }

  private clearItemTimer(item: ClientInteractionViewModel) {
    const timer = this.#timers.get(item.id);
    if (!timer) {
      return;
    }
    clearTimeout(timer);
    this.#timers.delete(item.id);
  }

  private onItemDeletedOnServer(id: number) {
    const item = this.storage.getItemById(id);
    if (!item) {
      return;
    }
    this.clearItemTimer(item);
    this.storage.deleteItem(id);
  }

  private onItemCreatedOnServer(item: ClientInteractionViewModel) {
    const threshold = this._thresholds[calcActualThresholdIdx(this._thresholds, new Date(item.waitingFrom))];
    this.storage.addItemToThreshold(threshold, item);
    this.setItemTimer(item);
  }

  loadItems() {
    return Promise.all(this._thresholds.map((threshold) => this.loadThresholdItems(threshold)));
  }
}
