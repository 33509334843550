import React from 'react';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds';
import { FeedbackViewProps } from '../view/types';
import { FeedbackView } from '../view';

export interface FeedbackCardProps extends FeedbackViewProps {}

export const FeedbackCard: React.FC<FeedbackCardProps> = (props) => {
  const theme = useTheme();

  return (
    <FeedbackView
      {...props}
      sx={mergeSx(
        {
          border: `1px solid ${theme.palette['gray middle']}`,
          borderRadius: `${theme.borderRadius.extraLarge}px`,
          padding: { xs: 2.5, [MOBILE_BREAKPOINT_THRESHOLD]: 2.5 },
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.white,
        },
        props.sx,
      )}
    />
  );
};
