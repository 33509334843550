import React from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { formatCatalogItemDate } from '@cp/entities/catalogItem';
import { AuthorRegistrationDateProps } from './model';

export const AuthorRegistrationDate: React.FC<AuthorRegistrationDateProps> = ({ data }) => {
  return data.clientProfile?.createdAt ? (
    <Typography sx={{ color: 'gray' }} variant="body">
      Дата регистрации: {formatCatalogItemDate(new Date(data.clientProfile.createdAt))}
    </Typography>
  ) : null;
};
