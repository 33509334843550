import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import React from 'react';

export interface IResumeViewContext {
  view: (id: ApiServerEntityIDType, onClose?: () => void) => void;
  hide?: () => void;
}

export const ResumeViewContext = React.createContext<IResumeViewContext>({
  view: () => {
    throw new Error('Resume view context is not provided');
  },
});
