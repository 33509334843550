import { coreApi } from '@cp/shared/api/core';
import { ApiRoute } from '@common/api/route';
import { generateApiUrl } from '@common/api/configure';
import { ApiRouteBody } from '@common/api/routeBody';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { CoreCatalogItemApiTags } from '@cp/entities/catalogItem';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { adminCatalogFeedbackApiTags } from './tags';

export const adminCatalogFeedbackApi = coreApi
  .enhanceEndpoints({
    addTagTypes: adminCatalogFeedbackApiTags,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      createFeedback: builder.mutation<void, { body: ApiRouteBody[ApiRoute.AdminCatalogFeedbacksCreate] }>({
        query: ({ body }) => {
          return {
            url: generateApiUrl(ApiRoute.AdminCatalogFeedbacksCreate),
            method: apiRouteMethodDict[ApiRoute.AdminCatalogFeedbacksCreate],
            body,
          };
        },
        invalidatesTags: (result, error, request) => {
          if (request.body.catalogItemId) {
            return [{ type: CoreCatalogItemApiTags.ResumeFeedbacks, id: request.body.catalogItemId }];
          }
          return [];
        },
      }),
      deleteFeedback: builder.mutation<void, { pathParams: ApiRoutePathParams[ApiRoute.AdminCatalogFeedbacksDelete] }>({
        query: ({ pathParams }) => {
          return {
            url: generateApiUrl(ApiRoute.AdminCatalogFeedbacksDelete, { id: pathParams.id }),
            method: apiRouteMethodDict[ApiRoute.AdminCatalogFeedbacksDelete],
          };
        },
        invalidatesTags: () => {
          return [{ type: CoreCatalogItemApiTags.ResumeFeedbacks }];
        },
      }),
    }),
  });
