import { RefObject, useEffect } from 'react';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { coreDialogApi } from '../api';
import { useCurrentDialogMember } from './useCurrentDialogMember';

export const useReadNotification = (ref: RefObject<HTMLElement>, message: DialogMessageViewModel) => {
  const { id: messageId, dialogId, deliveries } = message;
  const currentMember = useCurrentDialogMember();
  const myDelivery = deliveries.find((delivery) => delivery.recipientId === currentMember?.id);
  const [markMessageRead] = coreDialogApi.useMarkReadMutation();

  useEffect(() => {
    const element = ref.current;
    if (!element || !myDelivery || myDelivery.isRead) {
      return;
    }
    const observer = new IntersectionObserver(async ([entry], observerInstance) => {
      if (!entry.isIntersecting) {
        return;
      }
      observerInstance.unobserve(entry.target);
      await markMessageRead({ messageId, dialogId });
    });
    observer.observe(element);
    return () => observer.disconnect();
  }, [myDelivery, messageId, dialogId]);
};
