import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { mapCurrentUserToDialogUser } from '@common/model/dialog/mapCurrentUserToDialogUser';
import { User } from '../../user';
import { mapUserTypeToMemberType } from './mapUserTypeToMemberType';

export const getDialogMemberForUser = (members: DialogMemberViewModel[], currentUser: User): DialogMemberViewModel | undefined => {
  const memberType = mapUserTypeToMemberType(currentUser.type);

  if (!memberType) {
    return;
  }
  const mappedUser = mapCurrentUserToDialogUser({ id: currentUser.id, type: memberType });

  return members.find((member) => member.type === mappedUser.type && member.entityId === mappedUser.id);
};

export const getAllDialogMembersForUser = (members: DialogMemberViewModel[], currentUser: User): DialogMemberViewModel[] => {
  const memberType = mapUserTypeToMemberType(currentUser.type);

  if (!memberType) {
    return [];
  }

  const mappedUser = mapCurrentUserToDialogUser({ id: currentUser.id, type: memberType });

  return members.filter((member) => member.type === mappedUser.type && member.entityId === mappedUser.id);
};
