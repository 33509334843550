import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ArrowLeftIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="arrowLeft" ref={ref as any} {...props}>
      <path
        d="M6.81173 17.7071C7.1667 18.0976 7.74234 18.0976 8.09737 17.7071C8.4524 17.3167 8.4524 16.6835 8.09737 16.2929L5.10379 13H21.0909C21.593 13 22 12.5523 22 12C22 11.4477 21.593 11 21.0909 11H5.10379L8.09731 7.70707C8.45234 7.3166 8.45234 6.6834 8.09731 6.29287C7.91986 6.09767 7.68719 6 7.45452 6C7.22186 6 6.98919 6.09767 6.81173 6.29293L2.26627 11.2929C1.91124 11.6834 1.91124 12.3166 2.26627 12.7071L6.81173 17.7071Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
