import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AdminCreatePageRequest } from '@common/model/meta';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { LanguageCode } from '@common/model/resource/language';

export type TempRoute = AdminCreatePageRequest & { tempId: string };
const pageAdapter = createEntityAdapter<TempRoute>({ selectId: (m) => m.tempId });
const { selectAll } = pageAdapter.getSelectors();
export const name = 'routesTable';
export type ReducerStateShape = { [name]: EntityState<TempRoute> };
export const slice = createSlice({
  name,
  initialState: pageAdapter.getInitialState(),
  reducers: {
    addPage: (state) => {
      pageAdapter.addOne(state, {
        tempId: crypto.randomUUID(),
        pageId: ClientWebPage.Catalog,
        languageCode: LanguageCode.Russian,
        order: 1,
        path: '/change-me',
        title: '',
        description: '',
        keywords: '',
        breadcrumb: '',
        h1: '',
        props: {},
      });
    },
    pageSaved: (state, action: PayloadAction<{ tempId: string }>) => {
      pageAdapter.removeOne(state, action.payload.tempId);
    },
    deletePage: (state, action: PayloadAction<{ tempId: string }>) => {
      pageAdapter.removeOne(state, action.payload.tempId);
    },
    pageUpdated: (state, action: PayloadAction<TempRoute>) => {
      pageAdapter.upsertOne(state, action.payload);
    },
  },
});

export const reducerConfig = {
  [name]: slice.reducer,
};

export const selectItems = (rootState: ReducerStateShape) => selectAll(rootState[name]);
