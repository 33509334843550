import React, { MouseEventHandler, useCallback, useState } from 'react';
import { Button, ButtonProps } from '@cp/ds/src/components/button';
import { User } from '@cp/entities/user';
import { ClientType } from '@common/model/client/clientType';
import { Tooltip } from '@cp/ds/src/components/tooltip';
import { clientInteractionCoreApi } from 'src/entities/clientInteraction';
import { getUserFromInteraction } from '../model';

export interface ActivateSubjectButtonProps extends ButtonProps {
  interactionId: number;
  connectAs?: ClientType;
  activateSubject: (p: { id: number; asClient: User | null }) => PromiseLike<void>;
}

export const ActivateSubjectButton = ({ interactionId, connectAs, activateSubject, ...props }: ActivateSubjectButtonProps) => {
  const [load, { isLoading }] = clientInteractionCoreApi.endpoints.getDetails.useLazyQuery();
  const [title, setTitle] = useState<string>('');
  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      e.stopPropagation();
      const interactionDetails = await load({ id: interactionId }).unwrap();
      const asClient = connectAs ? getUserFromInteraction(connectAs, interactionDetails) : null;
      if (connectAs && asClient === null) {
        setTitle('Невозможно подключиться к диалогу');
        return;
      }
      return activateSubject({ id: interactionDetails.subjectId, asClient });
    },
    [load, interactionId, connectAs, activateSubject],
  );
  return (
    <Tooltip arrow colorSchema="error" title={title}>
      <Button disabled={isLoading} onClick={onClick} {...props} />
    </Tooltip>
  );
};
