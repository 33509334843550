import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { UserType } from '../../user';

export const mapUserTypeToMemberType = (userType: UserType): DialogMemberType | null => {
  switch (userType) {
    case UserType.Client:
      return DialogMemberType.Client;
    case UserType.Admin:
      return DialogMemberType.Admin;
    default:
      return null;
  }
};
