import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { adminRoutesApi } from 'src/widgets/routesTable/api';
import { TempRoute, slice } from './routesTable.slice';

export const useSaveTempRoute = () => {
  const dispatch = useDispatch();
  const [create] = adminRoutesApi.endpoints.createPage.useMutation();
  return useCallback(
    (route: TempRoute) => {
      return create(route)
        .unwrap()
        .then((savedRoute) => {
          dispatch(slice.actions.pageSaved(route));
          return savedRoute;
        })
        .catch((err) => {
          dispatch(slice.actions.pageUpdated(route));
          throw err;
        });
    },
    [create, dispatch],
  );
};
