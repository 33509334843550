/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { Stack } from '../stack';
import { Typography } from '../typography';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Button } from '../button';
import { PenIcon } from '../../icons/pen';
import { IconButton } from '../iconButton';
import { mergeSx } from '../../utils/sx/merge';
import { useTheme } from '../../hooks/useTheme';
import { Skeleton } from '../skeleton';
import { Box } from '../box';
import { MOBILE_BREAKPOINT_THRESHOLD, SxProps } from '../../theme';
import { EditableSectionProps } from './model';
export type { EditableSectionProps } from './model';

const EDIT_BUTTON_CONTAINER_SX: SxProps = { ml: 'auto', pl: 2 };

export const EditableSection: React.FC<EditableSectionProps> = ({
  leftBlock,
  title,
  emptyMessage,
  onEdit,
  sx,
  isLoading,
  children,
  buttonDataAttributes,
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      sx={mergeSx(
        {
          alignItems: { xs: 'flex-start', [MOBILE_BREAKPOINT_THRESHOLD]: 'center' },
          backgroundColor: 'background.default',
          borderRadius: '12px',
          border: `1px solid ${theme.palette['gray light']}`,
          overflow: 'hidden',
          p: isMobile ? 1.5 : 2,
        },
        sx,
      )}
    >
      {leftBlock}

      <Stack direction="column" sx={{ flex: '1 1 0px' }}>
        <Typography component="div" sx={{ typography: { xs: 'button', [MOBILE_BREAKPOINT_THRESHOLD]: 'h3' } }}>
          {title}
        </Typography>
        {isLoading ? (
          <Skeleton width={160} />
        ) : emptyMessage ? (
          <Typography sx={{ color: 'brand pink', mt: 1, mb: children ? 1.5 : 0 }} variant="body">
            {emptyMessage}
          </Typography>
        ) : (
          children
        )}
      </Stack>

      <Box sx={EDIT_BUTTON_CONTAINER_SX}>
        {isMobile ? (
          <Button {...buttonDataAttributes} disabled={isLoading} onClick={onEdit} variant="text">
            <PenIcon />
          </Button>
        ) : (
          <IconButton {...buttonDataAttributes} disabled={isLoading} onClick={onEdit}>
            <PenIcon />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
};
