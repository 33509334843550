const MIN_POSITIVE_RATING = 3;

export const isFeedbackRatingPositive = (rating: number) => {
  return rating >= MIN_POSITIVE_RATING;
};

export const feedbackSign = (parameters: { isPositive: boolean } | { rating: number }) => {
  const isPositive = 'isPositive' in parameters ? parameters.isPositive : isFeedbackRatingPositive(parameters.rating);
  return isPositive ? '👍🏻' : '👎🏻';
};
