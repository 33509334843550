import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Stack } from '@cp/ds/src/components/stack';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { feedbackSign } from '@common/model/catalog/feedback';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds';
import { formatCatalogItemDate } from '@cp/entities/catalogItem';
import { FeedbackViewProps } from './types';

export const FeedbackView: React.FC<FeedbackViewProps> = ({ data, feedbackCollapsedLength, feedbackCollapsedMaxLength, sx, actions }) => {
  const { authorName, review, rating, createdAt: _createdAt } = data;
  const theme = useTheme();
  const createdAt = formatCatalogItemDate(new Date(_createdAt));

  const getCollapsedDefaultState = React.useCallback(() => {
    return (
      Boolean(feedbackCollapsedLength) &&
      Boolean(feedbackCollapsedMaxLength) &&
      (review?.text?.length ? review.text.length > (feedbackCollapsedMaxLength as number) : false)
    );
  }, [review?.text?.length, feedbackCollapsedLength, feedbackCollapsedMaxLength]);

  const [isCollapsed, setCollapsed] = React.useState(getCollapsedDefaultState());

  React.useEffect(() => {
    setCollapsed(getCollapsedDefaultState());
  }, [getCollapsedDefaultState]);

  const reviewText = React.useMemo(() => {
    if (!isCollapsed || !review?.text || !feedbackCollapsedLength || !feedbackCollapsedMaxLength) {
      return review?.text;
    }

    const matchEnd = review.text.slice(feedbackCollapsedLength).match(/[\s\.\,]+/);
    const end = matchEnd ? Number(matchEnd.index) + feedbackCollapsedLength : null;

    if (!end || end > feedbackCollapsedMaxLength) {
      return review.text.slice(0, feedbackCollapsedLength).concat('... ');
    }

    return review.text.slice(0, end).concat('... ');
  }, [isCollapsed, review?.text, feedbackCollapsedLength, feedbackCollapsedMaxLength]);

  return (
    <Box
      sx={mergeSx(
        {
          padding: { xs: theme.spacing(1.5, 0), [MOBILE_BREAKPOINT_THRESHOLD]: 2.5 },
          borderBottom: `1px solid ${theme.palette['gray middle']}`,
        },
        sx,
      )}
    >
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="div" variant="body bold">
          {authorName}
        </Typography>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography component="div" sx={{ color: theme.palette.gray, ml: 1 }} variant="caption">
            {createdAt}
          </Typography>
          {actions}
        </Stack>
      </Stack>

      <Typography component="p" sx={{ mb: 0, mt: 1, flexGrow: 1 }} variant="body">
        {feedbackSign({ rating })} {reviewText}
        {isCollapsed ? (
          <Box
            component="button"
            onClick={() => setCollapsed(false)}
            role="button"
            sx={{
              typography: 'body bold',
              backgroundColor: 'transparent',
              border: 'none',
              color: theme.palette['brand pink'],
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:active, &:focus': { outline: 'none' },
              px: 0,
            }}
          >
            показать еще
          </Box>
        ) : null}
      </Typography>
    </Box>
  );
};
